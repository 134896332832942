<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="公司名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="编码">
              <a-input v-decorator="['code']" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="法人">
              <a-input v-decorator="['contact']" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="电话">
              <a-input v-decorator="['phone']" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="邮箱">
              <a-input v-decorator="['email']" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="银行"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <Bank
                :list="bankList"
                @addBank="addBank"
                @setBank="setBank"
                @removeBank="removeBank"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { fetchDetail, edit } from "@/api/setting/company";

import Bank from "./components/bank.vue";
export default {
  name: "editCompany",

  components: {
    Bank,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      id: null,

      bankList: [],
    };
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && id !== this.id) {
      this.id = id;
      fetchDetail({
        id,
      }).then((res) => {
        this.form.setFieldsValue({
          code: res.code,
          name: res.name,
          contact: res.contact,
          address: res.contact,
          phone: res.phone,
          email: res.email,
        });

        this.bankList = res.banks;
      });
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          edit({
            id: this.id,
            ...values,
            banks: this.bankList
              .filter(
                (item) => item.bankName && item.bankCode
              )
              .map((item) => {
                return {
                  ...item,
                  editable: undefined,
                };
              }),
          }).then(() => {});
        }
      });
    },

    addBank() {
      this.bankList.push({
        editable: true,
      });
    },

    setBank({ index, element }) {
      this.bankList.splice(index, 1, element);
    },

    removeBank(index) {
      this.bankList.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
